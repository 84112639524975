//
// user.scss
// Use this to write your custom SCSS
//

@media (min-width: 768px) {
  .mt-md-n6 {
    margin-top: -2rem !important;
  }
}

.badge-secondary-soft {
  background-color: rgba(80, 102, 144, 0.1);
  color: #506690;
}
.badge-pill {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  border-radius: 10rem;
}
