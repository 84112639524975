//
// close.scss
// Extended from Bootstrap
//

.close {
  &:hover,
  &:focus {
    outline: none;
  }
}
