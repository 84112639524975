//
// alert.scss
// Extended from Bootstrap
//

// Color variants
//
// Using Bootstrap's core alert-variant mixin to generate solid background color + yiq colorized text (and making close/links match those colors)

@each $color, $value in $theme-colors {
  .alert-#{$color} {
    @include alert-variant(
      color-level($value, $alert-bg-level),
      color-level($value, $alert-border-level),
      color-contrast(color-level($value, $alert-bg-level))
    );

    .alert-link,
    .close {
      color: color-contrast(color-level($value, $alert-bg-level));
    }

    hr {
      background-color: darken(color-level($value, $alert-border-level), 5%);
    }
  }
}
